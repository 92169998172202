import React from "react";
import "../../Styles/Merch.css";

const Merch = () => {
  return (
    <div className="merch-container">
      <h1 className="no-margin-padding gradient">Merch</h1>
      <h4>to be continued...</h4>
    </div>
  );
};

export default Merch;
